<template>
  <div class="contact">
    <banner
        banner1="https://n.tobidad.com/site/videos/contact/banner1.mp4?v=1.0.1"
        banner2="https://n.tobidad.com/site/videos/contact/banner2.mp4?v=1.0.1"
        text="联系我们"
        style="height: 500px;"
    />

    <div class="leave-word">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="" prop="name">
          <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入您的联系电话"></el-input>
        </el-form-item>
        <el-form-item label="" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请输入您的公司名称"></el-input>
        </el-form-item>
        <el-form-item label="" prop="email">
          <el-input v-model="form.email" placeholder="请输入您的邮箱"></el-input>
        </el-form-item>
        <el-form-item label="" prop="remark">
          <el-input type="textarea" v-model="form.remark" placeholder="备注"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import Banner from "@/components/Card/Banner";
export default {
  name: 'Contact',
  components: {Banner},
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        companyName: '',
        remark: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请填写备注', trigger: 'blur' }
        ]
      }
    }
  },
}
</script>
<style>
.leave-word {
  margin: 60px auto;
  width: 600px;
}
</style>
